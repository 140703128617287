.name-container {
    margin: 3vh 0 5vh 0;
}

.input {
    padding: 1.2vmin 0;
}

.invalid {
    border: 2px solid red;
}
