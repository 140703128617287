.livequiz-img {
    max-width: 50vmin;
    margin-top: 3vh;
    margin-bottom: 3vh;
}

@media only screen and (max-width: 500px) {
    .livequiz-img {
        max-width: 70vmin;
    }    
}