@font-face {
    font-family: 'Helvetica LT';
    src: url('HelveticaLT-Light.woff2') format('woff2'),
        url('HelveticaLT-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std Medium';
    src: url('HelveticaNeueLTStd-Md.woff2') format('woff2'),
        url('HelveticaNeueLTStd-Md.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std Bold';
    src: url('HelveticaNeueLTStd-Bd.woff2') format('woff2'),
        url('HelveticaNeueLTStd-Bd.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std Roman';
    src: url('HelveticaNeueLTStd-Roman.woff2') format('woff2'),
        url('HelveticaNeueLTStd-Roman.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica LT Std Black Condensed';
    src: url('helvetica-lt-black-condensed.woff2') format('woff2'),
        url('helvetica-lt-black-condensed.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue LT Std Heavy';
    src: url('HelveticaNeueLTStd-Heavy.woff2') format('woff2'),
        url('HelveticaNeueLTStd-Heavy.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica Neue LT Std BdCn';
    src: url('HelveticaNeueLTStd-BdCn.woff2') format('woff2'),
        url('HelveticaNeueLTStd-BdCn.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

.helvetica-lite {
    font-family: 'Helvetica LT';
}
.helvetica-medium {
    font-family: 'Helvetica Neue LT Std Medium';
}
.helvetica-bold {
    font-family: 'Helvetica Neue LT Std Bold';
}
.helvetica-roman {
    font-family: 'Helvetica Neue LT Std Roman';
}
.helvetica-heavy {
    font-family: 'Helvetica Neue LT Std Heavy';
}

.helvetica-black-condensed {
    font-family: 'Helvetica LT Std Black Condensed';
}

.helvetica-bold-condensed {
    font-family: 'Helvetica Neue LT Std BdCn';
}