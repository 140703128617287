@font-face {
    font-family: 'Broken Console';
    src: url('BrokenConsole-Bold.woff2') format('woff2'),
        url('BrokenConsole-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

.broken-console {
    font-family: 'Broken Console';
}
