.logo-img {
    max-width: 30vmin;
}

@media only screen and (max-width: 900px) {
    .logo-img {
        max-width: 40vmin;
    }    
}

@media only screen and (max-width: 500px) {
    .logo-img {
        max-width: 50vmin;
    }    
}