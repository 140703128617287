.container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    opacity: 0;
    animation-duration: 300ms;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-delay: 0.2s;
}

.name {
    font-size: calc(10px + 2.8vmin);
    margin-bottom: 2vw;
}

.rank {
    background: var(--text-gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: calc(10px + 7vmin);;
}

.rank-text {
    font-size: calc(10px + 4.5vmin);
}

.score {
    font-size: calc(10px + 8vmin);
    text-shadow: 0.3vmin 0 0 #1a1a4d,
                -0.3vmin 0 0 #1a1a4d,
                0 0.3vmin 0 #1a1a4d,
                0 -0.3vmin 0 #1a1a4d,
                0.3vmin 0.3vmin #1a1a4d,
                -0.3vmin -0.3vmin 0 #1a1a4d,
                0.3vmin -0.3vmin 0 #1a1a4d,
                -0.3vmin 0.3vmin 0 #1a1a4d;
}

.points-text {
    vertical-align: super;
    font-size: calc(10px + 3vmin);
}

.raw {
    font-size: calc(10px + 13vmin);
}

.medal {
    max-width: 35vmin;
    margin: 2vh 0;
}
