:root {
    --blue: #009fff;
    --pink: #ed5fc1;
    --green: #2fb99c;
    --violet: #a561ff;
    --yellow: #fee16a;
    --app-border-radius: 4px;
    --app-background-color: #241c6e;
    --text-stroke-color: #320d87;
    --text-gradient: linear-gradient(1deg,#fef26a,#fe6a6a);
}

.blue {
    background-color: var(--blue);
}
.pink {
    background-color: var(--pink);
}
.green {
    background-color: var(--green);
}
.violet {
    background-color: var(--violet);
}

.yellow {
    color: var(--yellow);
}
