
.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.circle::before {
    padding-bottom: 100%;
    height: 0;
    content: "";
}

.bounce-in {
    animation-name: bounceIn;
}

@keyframes bounceIn {
    0% {
        opacity: 0;
        transform: scale(0.3) translate3d(0, 0, 0);
    }

    50% {
        opacity: 0.9;
        transform: scale(1.1);
    }

    80% {
        opacity: 1;
        transform: scale(0.89);
    }

    100% {
        opacity: 1;
        transform: scale(1) translate3d(0, 0, 0);
    }
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}