
.pin-container {
    margin: 3vh 0 5vh 0;
}

.input {
    padding: 1.2vmin calc(10px + 6vmin);
}



.invalid {
    border: 2px solid red;
}
