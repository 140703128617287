
.buttons {
    width: calc(50% - 1.5vmin - 8px);
    border: 3px solid transparent;
    cursor: pointer;
    height: calc(50% - 1.5vmin - 8px);
    border-radius: 20px;
}

.buttons:nth-child(n+3) {
    margin-top: 3vmin;
}

.buttons:nth-child(odd) {
    margin-right: 3vmin;
}

.single {
    width: 100%;
    height: 100%;
}

.wrapper {
    width: 80%;
    height: 100%;
}

.multiple {
    width: 100%;
    height: calc(100% - 12vmin);
}

.single,
.multiple {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.validation {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2vmin;
}

.disabled {
    pointer-events: none;
    opacity: 0.3;
}

.selected {
    border-color: #fff;
}