.button {
    position: relative;
    border: none;
    display: inline-flex;
    background-color: #fef26a;
    color: #8757f4;
    box-shadow: 0 4px 0 0 #f6990c;
    border-radius: 30vmin;
    padding: 1.2vmin 1vmin 1.2vmin 2vmin;
    align-items: center;
    cursor: pointer;
    min-width: 26vmin;
    justify-content: space-between;
}

.text {
    letter-spacing: -.1rem;
    font-size: calc(10px + 2.1vmin);
    margin-left: 2vmin;

    text-shadow: 0.25vmin 0 0 #fff,
                -0.25vmin 0 0 #fff,
                0 0.25vmin 0 #fff,
                0 -0.25vmin 0 #fff,
                0.25vmin 0.25vmin #fff,
                -0.25vmin -0.25vmin 0 #fff,
                0.25vmin -0.25vmin 0 #fff,
                -0.25vmin 0.25vmin 0 #fff;
}

.circle {
    background-color: #fff;
    font-size: calc(10px + 1vmin);
    padding: 0.7vmin;
    box-shadow: 0px 5px 3px 0px #fac136;
    margin-left: 2.52vmin;
}

.circle i {
    display: flex;
}
