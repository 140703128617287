.team {
    margin-bottom: 2vmin;
}
.team span {
    background-color: #fff;
    color: #000;
    border-radius: 4px;
    padding: .5vmin 1vmin;
    font-weight: bold;
}

.ul {
    padding: 0;
}

.li {
    list-style: none;
    text-align: center;
}

.topMargin {
    margin-top: 2vh;
}

.name {
    font-size: calc(10px + 3.4vmin);
}