@font-face {
    font-family: 'Bango Pro';
    src: url('BangoPro.woff2') format('woff2'),
        url('BangoPro.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Bango Pro';
    src: url('BangoPro.woff2') format('woff2'),
        url('BangoPro.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

.bango {
    font-family: 'Bango Pro';
}