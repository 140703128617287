.count {
    position: absolute;
    opacity: 0;
    animation-duration: 450ms;
    animation-timing-function: unset;
    animation-fill-mode: forwards;
    animation-delay: 0.4s;
    font-size: calc(10px + 15vmin);

    text-shadow: 0.3vmin 0 0 var(--text-stroke-color),
                -0.3vmin 0 0 var(--text-stroke-color),
                0 0.3vmin 0 var(--text-stroke-color),
                0 -0.3vmin 0 var(--text-stroke-color),
                0.3vmin 0.3vmin var(--text-stroke-color),
                -0.3vmin -0.3vmin 0 var(--text-stroke-color),
                0.3vmin -0.3vmin 0 var(--text-stroke-color),
                -0.3vmin 0.3vmin 0 var(--text-stroke-color);
}

.content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2vh;
}
