.wrapper {
    background-size: cover;
    background-position: 50%;
    font-size: calc(10px + 2.4vmin);
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    height: 100%;
    overflow-y: auto;
}

