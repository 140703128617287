.container {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #fff;
}

.header,
.footer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    display: flex;
    align-items: center;
    background-color: #4a89c6;
    padding: 2vmin 7vmin;
    border-radius: 2vmin 2vmin 1vmin 1vmin;

    font-size: calc(10px + 2.7vmin);
    min-width: 45vmin;
    justify-content: space-between;
    text-shadow: 0.25vmin 0 0 var(--text-stroke-color),
                -0.25vmin 0 0 var(--text-stroke-color),
                0 0.25vmin 0 var(--text-stroke-color),
                0 -0.25vmin 0 var(--text-stroke-color),
                0.25vmin 0.25vmin var(--text-stroke-color),
                -0.25vmin -0.25vmin 0 var(--text-stroke-color),
                0.25vmin -0.25vmin 0 var(--text-stroke-color),
                -0.25vmin 0.25vmin 0 var(--text-stroke-color);
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    flex-wrap: wrap;
    padding: 0 2vmin 7vmin 2vmin;
}

.linear-padding {
    padding: 2vmin;
}

.countdown {
    padding: 2vmin;
}

.question-progression {
    background-color: #f69a0f;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 21vmin;
    transform: translateY(-30%);
}

.question-progression-value {
    font-size: calc(10px + 4.5vmin);
    letter-spacing: -0.1rem;
    transform: translateY(25%);
    text-shadow: 0.3vmin 0 0 #963a10,
                -0.3vmin 0 0 #963a10,
                0 0.3vmin 0 #963a10,
                0 -0.3vmin 0 #963a10,
                0.3vmin 0.3vmin #963a10,
                -0.3vmin -0.3vmin 0 #963a10,
                0.3vmin -0.3vmin 0 #963a10,
                -0.3vmin 0.3vmin 0 #963a10;
}

.score-container {
    margin-left: 6vmin;
}

.score {
    font-size: calc(10px + 3.3vmin);
}

.result,
.points {
    letter-spacing: -.1rem;
}

.result {
    font-size: calc(10px + 6.2vmin);
    text-shadow: 0.5vmin 0 0 var(--text-stroke-color),
                -0.5vmin 0 0 var(--text-stroke-color),
                0 0.5vmin 0 var(--text-stroke-color),
                0 -0.5vmin 0 var(--text-stroke-color),
                0.5vmin 0.5vmin var(--text-stroke-color),
                -0.5vmin -0.5vmin 0 var(--text-stroke-color),
                0.5vmin -0.5vmin 0 var(--text-stroke-color),
                -0.5vmin 0.5vmin 0 var(--text-stroke-color);
}

.points {
    font-size: calc(10px + 13vmin);
    text-shadow: 0.6vmin 0 0 var(--text-stroke-color),
                -0.6vmin 0 0 var(--text-stroke-color),
                0 0.6vmin 0 var(--text-stroke-color),
                0 -0.6vmin 0 var(--text-stroke-color),
                0.6vmin 0.6vmin var(--text-stroke-color),
                -0.6vmin -0.6vmin 0 var(--text-stroke-color),
                0.6vmin -0.6vmin 0 var(--text-stroke-color),
                -0.6vmin 0.6vmin 0 var(--text-stroke-color);
}

.points-text {
    font-size: calc(10px + 6.5vmin);
}

.min {
    font-size: calc(10px + 5.1vmin);
    letter-spacing: initial;
}

.podium-text {
    font-size: calc(10px + 1.45vmin);
}

.responses-chain {
    font-size: calc(10px + 4vmin);
    font-weight: bold;
    margin-left: 2vmin;
}

.margin {
    margin: 3vh 0;
}

.background {
    background-color: rgba(79, 47, 162, 0.5);
    font-size: calc(10px + 1.45vmin);
    padding: 2.5vmin 1.5vmin;
    border-radius: 50vmin;
    min-width: 60vmin;

    height: 4vmin;
    display: flex;
    justify-content: center;
    align-items: center;
}

.svg {
    width: 13vmin;
}