
.name-container {
    margin: 3vh 0 5vh 0;
}

.team-container {
    margin: 2vh 0 0;
}

.input {
    padding: 1.2vmin 0;
}

.item {
    padding: 1vmin 0;
    margin-bottom: 1vh;
    font-size: calc(10px + 4vmin);

    opacity: 0;
    animation-duration: 450ms;
    animation-timing-function: unset;
    animation-fill-mode: forwards;
    animation-delay: 0s;
}

.invalid {
    border: 2px solid red;
}


.circle {
    background-color: #fff;
    font-size: calc(10px + 1vmin);
    padding: 0.7vmin;
    margin-left: 2.52vmin;
    color: #8757f4;
    cursor: pointer;
}

.circle i {
    display: flex;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1vh;
}

.margin-bottom {
    margin-bottom: 2vh;
}