.wrapper {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.input {
    font-family: 'Helvetica LT';
    font-weight: normal;
    font-size: calc(10px + 2.3vmin);
    padding-left: 0.5rem;
    color: #333;
    width: 100%;
}

.input-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    max-width: 38rem;
    padding: 1rem;
    height: 7vmin;
    border-radius: 20px;
    background-color: #fff;
    color: #333;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}

.validation {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2vmin;
}