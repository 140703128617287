
.wrapper {
    width: 80%;
    height: 100%;
}

.sortable_container {
    width: 100%;
    height: calc(100% - 4rem);
}

.sortable_container > div {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.sortable_item {
    position: relative;
    width: 100%;
    border-radius: 20px;
    flex: 1;
    cursor: pointer;
}

.sortable_container .sortable_item:not(:last-child) {
    margin-bottom: 3vmin;
}

.validation {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2vmin;
}


.order-icon {
    font-size: 3.3rem;
    position: absolute;
    top: 50%;
    left: 0;
    display: flex;
    transform: translate(-50%, -50%);
    color: #fff;
}

.font-icon::after {
    content: "";
    background-color: #8b8b8b;
    width: 50%;
    height: 50%;
    border-radius: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
}