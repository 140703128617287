.input {
    border: 2px solid #fff;
    border-radius: 30vmin;
    font-size: calc(10px + 4vmin);
    text-align: center;
    font-weight: bold;
    background-color: transparent;
    color: #fff;
    outline: none;
}

.input::placeholder {
    color: inherit;
    opacity: 0.3;
}